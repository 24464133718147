<template>
  <div id="ProducerPublicConsumption">
    <QsCard :qlik-ids="['WKzfsG']" />
    <QsCard :qlik-ids="['fDEyG']" />
    <QsCard :qlik-ids="['JtzfrC']" />
    <QsCard :qlik-ids="['WznPpe']" />
    <QsCard :qlik-ids="['HsfHUs']" />
    <QsCard :qlik-ids="['zhfhXS']" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsCard } };
</script>

<style scoped>
#ProducerPublicConsumption {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 300px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
